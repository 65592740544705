<template>
    <div class="w-full h-full flex flex-col items-center justify-center relative">

        <div class="h-auto absolute top-0 left-0 mt-8 ml-2 flex flex-row justify-center items-center" @click="exit()">
            <i class="mdi mdi-arrow-left text-2xl text-red"></i>
        </div>

        <div class="px-2 pt-2">
            <img class="mx-auto w-auto h-16" alt="Vue logo" src="/img/LOGOGRUPO.png">
        </div>

        <div class="h-auto mt-6 w-11/12 flex flex-row justify-center items-center">
            <span class="text-red text-center">{{$t('firstAccessChangePassword')}}</span>
        </div>

        <Request
            model="Changeme"
            action="update"
            :form="form"
            @success="onSuccess"
            @error="onError"
            v-slot="{ request, loading }" >

            <div class="h-auto w-full m-10/12 mx-auto">

                <div class="h-auto w-10/12 mx-auto flex flex-col mt-4" @keypress.enter="request">
                    <!-- <input class="shadow-card border-gray w-full h-10 px-2 rounded-lg mt-2" type="password" v-model="form.password" placeholder="Nueva Contraseña">
                    <input class="shadow-card border-gray w-full h-10 px-2 rounded-lg mt-2" type="password" v-model="verification" placeholder="Verifica Nueva Contraseña"> -->

                    <!-- password -->
                    <div class="h-auto px-2 flex flex-col justify-center items-start">

                        <span class="text-sm text-dfont mb-1">{{$t('newPassword')}}</span>

                        <div class="h-8 w-full">

                            <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2 relative">

                                <input :type="typepassword" :placeholder="$t('password')" class="w-full bg-lightgray text-sm" v-model="form.password">

                                <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass()">
                                    <i v-if="typepassword == 'password'" class="mdi mdi-eye text-dfont"></i>
                                    <i v-if="typepassword == 'text'" class="mdi mdi-eye-off text-dfont"></i>
                                </div>

                            </div>

                        </div>

                    </div>

                    <!-- password2 -->
                    <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">

                        <span class="text-sm text-dfont mb-1">{{$t('repeatPassword')}}</span>

                        <div class="h-8 w-full">

                            <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2" >

                                <input :type="typepassword2" :placeholder="$t('repeatPassword')" class="w-full bg-lightgray text-sm" v-model="verification">

                                <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass2()">
                                    <i v-if="typepassword2 == 'password'" class="mdi mdi-eye text-dfont"></i>
                                    <i v-if="typepassword2 == 'text'" class="mdi mdi-eye-off text-dfont"></i>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div v-if="(form.password === verification && form.password != '')" @click="changepass(request)"
                        :class="{ 'opacity-50': loading }"
                        :disabled="loading" class="h-10 w-auto bg-red p-2 flex flex-row justify-center items-center rounded-lg mt-4">
                        <span class="text-white">{{$t('changePassword')}}</span>
                    </div>
                </div>
                <div class="w-full py-4 text-gray text-center text-sm" v-if="show">
                    {{$t('passwordSuccessfullyChanged')}}
                </div>

                <div v-if="errormodal" class="absolute h-full w-full top-0 left-0 flex col justify-center items-center" style="backdrop-filter:blur(3px)">

                    <div class="h-auto w-4/5 bg-white rounded-lg shadow-card flex flex-col overflow-hidden">

                        <div class="h-10 w-full bg-red flex flex-row justify-between items-center px-2">

                            <span class="text-white font-semibold">{{$t('unsecuredPassword')}}</span>

                            <div class="h-10 w-10 flex flex-col justify-center items-center" @click="errormodal = !errormodal">
                                <i class="mdi mdi-close text-white"></i>
                            </div>

                        </div>

                        <div class="flex-1 flex flex-col justify-center items-start p-4">

                            <span class="text-dfont font-semibold mb-2"><span class="font-bold">·</span> {{$t('passwordMustBe')}}</span>
                            <span class="text-dfont"><span class="font-bold">·</span> {{$t('betweenCharacters')}}</span>
                            <span class="text-dfont"><span class="font-bold">·</span> {{$t('leastOneDigit')}}</span>
                            <span class="text-dfont"><span class="font-bold">·</span> {{$t('leastLowerAndUpperCase')}}</span>

                        </div>

                    </div>

                </div>

            </div>

        </Request>

    </div>
</template>

<script>
    import { Request } from '@/api/components';
    import { routeByUser } from '@/router/helpers';
    import { Auth } from '@/api/models';
    import { actions, state } from '@/store';

    export default {
        name: 'Login',
        components: {
            Request
        },
        data() {
            return {
                form: {
                    Id: state.user.id,
                    password: '',
                    forcepassword: 0
                },
                verification: null,
                show:false,
                typepassword: 'password',
                typepassword2: 'password',
                errormodal: false
            };
        },
        computed: {
            user() {
                return state.user;
            }
        },
        methods: {
            async onSuccess(){
                const user = await Auth.me();
                actions.setUser(user);
                this.show=true;

                const route = routeByUser(user);
                this.$router.push(route);
            },

            onError(){

            },

            exit(){
                actions.resetAuth();
                this.$router.push({ name: 'login' });
            },
            validatePass(){

                if(this.form.password == this.verification){

                    let passRegex = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

                    if(passRegex.test(this.form.password)){

                        return true

                    }else{

                        return false
                    }

                } else {

                    return false

                }


            },
            changepass(request){

                if(this.validatePass()){
                    request()
                } else {
                    this.errormodal = !this.errormodal
                }

            },

            typepass(){
                
                if(this.typepassword == 'password'){
                    this.typepassword = 'text'
                } else {
                    this.typepassword = 'password'
                }
            },

            typepass2(){
                
                if(this.typepassword2 == 'password'){
                    this.typepassword2 = 'text'
                } else {
                    this.typepassword2 = 'password'
                }
            }
        },
        mounted() {
            const route = routeByUser(this.user);
            this.$router.push(route);
        }
    };
</script>
